import React from 'react';
import GooglePlayStore from '../Assets/googleplay.png';
import AppStore from '../Assets/appstore.png';
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles( theme => ({
    storesLinks: {
        padding: 20
    },
    links: {
        margin: 20
    }

}));

const linksUrls = {
    apple: 'https://apple.com',
    google: 'https://google.com'
}

const StoreButtons = () => {
    const classes = useStyles();
    return (
        <div className={classes.storesLinks}>
            <a className={classes.links} href={linksUrls.google} target='_blank'>
                <img src={GooglePlayStore}/>
            </a>
            <a className={classes.links} href={linksUrls.apple}  target='_blank'>
                <img src={AppStore}/>
            </a>
        </div>
    );
};

export default StoreButtons;
