import React from 'react';
import { Grid, Container } from '@material-ui/core'
import makeStyles from "@material-ui/core/styles/makeStyles";
import LogoWhite from '../Assets/logo_w.png';
import FeatureList from "./FeatureList";
import FeatureItem from "./FeatureItem";
import DoctorsImages from '../Assets/doctors.png'

const useStyles = makeStyles( theme => ({
    footerInfoContainer: {
        height: 150,
        backgroundColor: '#333333',
        padding: 20
    },
    footerfeaturesContainer: {
        padding: 20
    },
    text:{
        color: '#FFFFFF',
        fontSize: 13
    }
}));

const socialNetworks = [
    {

    }
]

const texts = {
    title: 'Dr.Mias:',
    paragraph: 'An innovating Healthcare Platform  to connect easy Patients with Doctors and Providers, all in one place.'
}

const contentFooterParagraph = <div>
    <h2>{texts.title}</h2>
    <p>{texts.paragraph}</p>
</div>


const Footer = () => {
    const classes = useStyles();

    return (
        <div>
            <div className={classes.footerfeaturesContainer}>
                <Container>
                    <FeatureItem rightContent={contentFooterParagraph} leftContent={<img style={{ maxHeight: 250, paddingLeft: '20%'}} src={DoctorsImages}/>} haveColor={false} />
                </Container>
            </div>
            <div className={classes.footerInfoContainer}>
                <Container>
                    <Grid container>
                        <Grid xs={8}>
                            <img src={LogoWhite} />
                            <p className={classes.text}>Copyright 2020 Dr.Mias - All Rights Reserved </p>
                            <p className={classes.text}>Terms of Service | Privacy Policy </p>
                        </Grid>
                        <Grid xs={4}>

                        </Grid>
                    </Grid>
                </Container>
            </div>
        </div>
    );
};

export default Footer;
