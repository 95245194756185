import React from 'react';
import Header from "../Components/Header";
import MainBanner from "../Components/MainBanner";
import Features from "../Components/Features";
import FeatureVideo from "../Components/FeatureVideo";
import FeatureTelemedicine from "../Components/FeatureTelemedicine";
import FeatureHealthyLogs from "../Components/FeatureHealthyLogs";
import BottomBanner from '../Components/BottomBanner'
import Footer from "../Components/Footer";

const LandingPage = () => {
    return (
        <>
            <Header />
            <MainBanner />
            <Features />
            <FeatureVideo />
            <FeatureTelemedicine />
            <FeatureHealthyLogs />
            <BottomBanner />
            <Footer />
        </>
    );
};

export default LandingPage;
