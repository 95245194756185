import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Grid from '@material-ui/core/Grid';
import bgBanner from '../Assets/bg_banner.jpg';
import mobileImage from '../Assets/mobile1.png';
import Container from "@material-ui/core/Container";
import {Hidden} from "@material-ui/core";
import StoreButtons from "./StoreButtons";

const useStyles = makeStyles( theme => ({
    backgroundBanner: {
        backgroundImage: `url(${bgBanner})`,
        backgroundPosition: 'bottom',
        width: '100%',
        height: 700
    },
    content: {
        paddingTop: 20,
        paddingBottom: 20,
        color: '#FFFFFF'
    },
    mobileImage: {
        marginTop: 70,
        height: 500
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: 500,
        width: '100%'
    }
}));

const MainBanner = () => {
    const classes = useStyles();

    return (
        <div className={classes.backgroundBanner}>
            <Container className={classes.content}>
                <Grid container>
                    <Grid xs={12} md={7} className={classes.textContent}>
                        <h1>Stay connected with Dr.Mias</h1>
                        <p>With Dr.Mias - Get connected with Doctors, Patients and Providers  easily,  all in one platform.</p>
                        <StoreButtons />
                    </Grid>
                    <Hidden smDown>
                        <Grid md={5}>
                            <img className={classes.mobileImage} src={mobileImage}/>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </div>
    );
};

export default MainBanner;
