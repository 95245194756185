import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
    palette: {
        primary: {
            main: '#5A2E9F',
        },
        secondary: {
            main: '#21A7FC',
        },
    },
    typography: {
        fontFamily: [
            'Montserrat',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(','),
    },
});
