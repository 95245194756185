import React from 'react';
import {Grid, Container} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles( theme => ({
    mainContentWithBg: {
        backgroundColor: '#EEEEEE',
        minHeight: 300

    },
    mainContentWithoutBg: {
        backgroundColor: '#FFFFFF',
        padding: 40
    },
    content: {
        paddingLeft: 20
    }
}));

const FeatureItem = ({ rightContent, leftContent, haveColor }) => {
    const classes = useStyles();
    return (
        <div className={ haveColor === true ? classes.mainContentWithBg : classes.mainContentWithoutBg }>
            <Container>
                <Grid container>
                    <Grid xs={12} md={6} >
                        <div className={ classes.content}>
                            {rightContent}
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div className={ classes.content}>
                            {leftContent}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>);
};

export default FeatureItem;
