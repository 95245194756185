import React from 'react'
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
  root: {
    '& label': {
      color: 'white',
      borderBottomColor: 'white',
      borderColor: 'white',
    },
    '& label.Mui-focused': {
      color: 'white',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'white',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'white',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
})(TextField);

export default function InputDR({label, type, onChange, disabled, value }) {
  return (
    <CssTextField label={label} type={type} onChange={onChange} disabled={disabled} value={value}/>
  )
}
