import React from 'react';
import FeatureItem from "./FeatureItem";
import FeatureList from "./FeatureList";
import HealthyLogsImage from "../Assets/healthylogs.jpg"

const texts = [
    "Easily share your Heathy Logs with your doctor.",
    "Weight, Blood Pressure and Heart Rate"
];

const FeatureHealthyLogs = () => {
    return (
        <div>
            <FeatureItem rightContent={<FeatureList title={"Healthy Logs"} texts={texts}/>} leftContent={<img src={HealthyLogsImage}/>} haveColor={true} />
        </div>
    );
};

export default FeatureHealthyLogs;
