import ConnectionAdded from "./Pages/ConnectionAdded";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import LandingPage from "./Pages/LandingPage";
import ResetPassword from "./Pages/ResetPassword";

export default function App() {
  return (
    <Router>
        <Switch>
          <Route path="/user/password/edit">
            <ResetPassword />
          </Route>
          <Route path="/connection_added">
            <ConnectionAdded added />
          </Route>
          <Route path="/connection_not_added">
            <ConnectionAdded added={false}/>
          </Route>
          <Route exact path="/">
            <LandingPage />
          </Route>
        </Switch>
    </Router>
  );
}
