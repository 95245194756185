import React from 'react';
import logo from '../Assets/logo.png';
import Container from "@material-ui/core/Container";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles( theme => ({
    header: {
        display: 'flex',
        marginTop: 20,
        marginBottom: 20,
        alignItems: 'center',
        justifyContent: 'center',
        alignContent: 'center'
    },
    image: {
        maxHeight: 55
    }
}));

const Header = () => {
    const classes = useStyles();

    return (
        <Container className={classes.header}>
            <img className={classes.image} src={logo} />
        </Container>
    );
};

export default Header;
