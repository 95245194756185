import React from 'react';
import {Grid, Container} from "@material-ui/core";
import BackgroundRed from '../Assets/bg_red.jpg'
import makeStyles from "@material-ui/core/styles/makeStyles";
import MobileImage from '../Assets/mobile4.png';
import FeatureList from "./FeatureList";

const useStyles = makeStyles( theme => ({
    mainContent: {
        backgroundImage: `url(${BackgroundRed})`,
        padding: 40,
        minHeight: 500,
        backgroundPosition: 'bottom',
        backgroundSize: 'cover'
    },
    content: {
        paddingLeft: 20
    },
    list: {
        padding: 30
    },
    image: {
        maxWidth: '100%'
    }
}));

const texts = [
    "You can share instructional videos to your partners and patients. ",
    "Easily schedule a meeting with your partners and patients.",
    "Easily share your Heathy Logs with your doctor."
];

const FeatureItem = () => {
    const classes = useStyles();
    return (
        <div className={ classes.mainContent }>
            <Container>
                <Grid container>
                    <Grid xs={12} md={6} >
                        <div className={ classes.list}>
                            <FeatureList texts={texts} dark/>
                        </div>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <div className={ classes.content}>
                            <img className={classes.image} src={MobileImage} />
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>);
};

export default FeatureItem;
