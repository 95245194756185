import React from 'react'
import Header from "../Components/Header";
import Footer from "../Components/Footer";
import FormResetPassword from "../Components/ResetPassword";

export default function ResetPassword() {
  return (
    <>
      <Header />
      <FormResetPassword />
      <Footer />
    </>
  )
}
