import React from 'react';
import FeatureItem from "./FeatureItem";
import FeatureList from "./FeatureList";
import TelemedicineVideo from "../Assets/telemedicine_video.mp4";
import TelemedicineImage from "../Assets/telemedicine.jpg"
import ReactPlayer from "react-player";

const texts = [
    "Easily schedule a meeting with your partners and patients. ",
    "Have privacy with between phisycian and patient. ",
    "Send messages to patients. ",
];

const FeatureTelemedicine = () => {
    return (
        <div>
            <FeatureItem leftContent={<FeatureList title={"Telemedicine"} texts={texts}/>} rightContent={<img src={TelemedicineImage} />} haveColor={false} />
        </div>
    );
};
export default FeatureTelemedicine;
