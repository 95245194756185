import React from 'react';
import FeatureItem from "./FeatureItem";
import FeatureList from "./FeatureList";
import ReactPlayer from "react-player";
import DrmiasVideo from '../Assets/drmias_1video.m4v';
import {Hidden} from "@material-ui/core";

const texts = [
    "You can share instructional videos to your partners and patients.",
    "You Can Learn From Videos.",
    "Discover new procedure trends with medical videos. "
];

const FeatureVideo = () => {
    return (
        <div>
            <Hidden smDown>
            <FeatureItem
                rightContent={
                    <FeatureList title={"Video"} texts={texts}/>
                }
                leftContent={
                    <ReactPlayer playing muted loop height={400} autoplay url={DrmiasVideo}/>
                }
                haveColor={true}
            />
            </Hidden>
            <Hidden mdUp>
            <FeatureItem
                rightContent={
                    <FeatureList title={"Video"} texts={texts}/>
                }
                haveColor={true}
            />
            </Hidden>
        </div>
    );
};

export default FeatureVideo;
