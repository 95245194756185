import React, { useState } from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {withStyles} from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import bgBanner from '../Assets/bg_banner.jpg';
import mobileImage from '../Assets/mobile1.png';
import Container from "@material-ui/core/Container";
import {Button, Hidden} from "@material-ui/core";
import { useLocation } from 'react-router-dom'
import InputDR from "./InputDR"

import axios from 'axios'

const useStyles = makeStyles( theme => ({
    backgroundBanner: {
        backgroundImage: `url(${bgBanner})`,
        backgroundPosition: 'bottom',
        width: '100%',
        height: 700
    },
    content: {
        paddingTop: 20,
        paddingBottom: 20,
        color: '#FFFFFF'
    },
    mobileImage: {
        marginTop: 70,
        height: 500
    },
    textContent: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        height: 500,
        width: '100%'
    }
}));

const ColorButton = withStyles((theme) => ({
    root: {
      color: 'white',
      backgroundColor: '#610025',
      '&:hover': {
        backgroundColor: '#610025',
      },
    },
  }))(Button);

function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const ResetPassword = () => {
    const classes = useStyles();
    const q = useQuery()
    const reset_password_token = q.get('reset_password_token')

    const [newPassword, setNewPassword] = useState('')
    const [confirmationPassword, setConfirmationPassword] = useState('')
    const [sending, setSending] = useState(false)
    const [passwordChanged, setPasswordChanged] = useState(false)

    const isSending = () => sending
    const validatePassword = () => (newPassword !== '') && (newPassword.length > 5);
    const validateConfirmation = () => ( newPassword === confirmationPassword ) && (confirmationPassword.length > 5);;

    const sendRequestToApi = () => {
        setSending(true)
        axios.post('https://api.mia.ylabs.com.br/reset_password', {
            reset_password_token,
            new_password: newPassword
        }).then(() => {
            setNewPassword('')
            setConfirmationPassword('')
            setSending(false)
            setPasswordChanged(true)
        })
        setPasswordChanged(true)
    }

    return (
        <div className={classes.backgroundBanner}>
            <Container className={classes.content}>
                <Grid container>
                    <Grid xs={12} md={4} className={classes.textContent}>
                        { reset_password_token && !passwordChanged &&
                        <>
                            <h1>Reset password</h1>
                            <p>Please choose your new password:</p>
                            <InputDR label="Type your new password" type="password" value={newPassword} disabled={isSending() } onChange={ e => setNewPassword(e.target.value)}/>
                            { !validatePassword() && !(newPassword === '') && <p>The length of the password should be more than 6 </p> }
                            <InputDR label="Confirm your new password" value={confirmationPassword} disabled={!validatePassword() || isSending() } type="password" onChange={ e => setConfirmationPassword(e.target.value)}/>
                            { validatePassword() && !validateConfirmation() && !(confirmationPassword === '') && <p>Should be equal to the new password. </p> }
                            { validatePassword() && validateConfirmation() && <ColorButton disabled={isSending()} onClick={sendRequestToApi} style={{marginTop: 20}} variant="contained" color="secondary">Change email</ColorButton> }
                        </> }

                        { passwordChanged && <h1>Your password has changed!</h1> }
                    </Grid>
                    <Hidden smDown>
                        <Grid md={8} style={{ display: 'flex', alignContent: 'center', justifyContent: 'center'}} >
                            <img className={classes.mobileImage} src={mobileImage}/>
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
        </div>
    );
};

export default ResetPassword;
