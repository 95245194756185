import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

import drImage from '../Assets/item1.png';
import videoImage from '../Assets/item2.png';
import heartImage from '../Assets/item3.png'

const useStyles = makeStyles(theme => ({
    content: {
        padding: 20
    },
    squares: {
        margin: 10,
        height: 200,
        borderColor: '#cccccc',
        borderWidth: 1,
        border: 'solid',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        textAlign: 'center'
    },
    squareContainer: {
        padding: 0
    },
    squareImage: {
        height: 70,
        width: 70
    }
}))

const Features = () => {
    const classes = useStyles();
    return (
        <Container>
            <Grid container className={classes.content}>
                <Grid xs={12}>
                    <h1>What is Dr.Mias</h1>
                    <p> Dr.Mias is an innovating Healthcare Platform  to connect easy Patients with Doctors and Providers, all in one place.</p>
                </Grid>
                <Grid xs={12} md={4} className={classes.squareContainer} >
                    <div className={classes.squares}>
                        <img className={classes.squareImage} src={drImage} />
                        <p>Connecting doctors and your provider network to share experiences and discuss cases. Drmias also connects patients with your doctors ealisy.</p>
                    </div>
                </Grid>
                <Grid xs={12} md={4} className={classes.squareContainer} >
                    <div className={classes.squares}>
                        <img className={classes.squareImage} src={videoImage}/>
                        <p>Drmias has a libraries of medical videos to be shared among the patients and doctors, to educate them.</p>
                    </div>
                </Grid>
                <Grid xs={12} md={4} className={classes.squareContainer} >
                    <div className={classes.squares}>
                        <img className={classes.squareImage} src={heartImage} />
                        <p>Drmias help you to be in sync with you doctor by sharing your healthy logs with them.</p>
                    </div>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Features;
