import React from 'react';
import Router from "./Router";
import theme from './theme'
import { ThemeProvider } from '@material-ui/styles';

function App() {
  return <ThemeProvider theme={theme}>
    <Router />
  </ThemeProvider>
}

export default App;
