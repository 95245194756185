import React from 'react';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import Grid from "@material-ui/core/Grid";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles( theme => ({
    content: {
        padding: 10
    },
    title: {
        width: '100%',
        textAlign: 'center'
    },
    arrow: {
        color: '#a80744'
    },
    arrowDark: {
        color: '#F8CE03'
    },
    textDark: {
        color: '#FFFFFF'
    }
}));
const FeatureList = ({texts, title, dark}) => {
    const classes = useStyles();
    return (
        <div>
            <div className={classes.title}>
                <h1>{title}</h1>
            </div>
            { texts.map( text =>
                <div className={classes.content}>
                    <Grid container>
                        <Grid xs={1}>
                            <ArrowRightIcon className={ dark ? classes.arrowDark : classes.arrow} />
                        </Grid>
                        <Grid xs={11} className={ dark ? classes.textDark : false}>
                            {text}
                        </Grid>
                    </Grid>
                </div>
            ) }
        </div>
    );
};

export default FeatureList;
